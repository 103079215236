import moment from 'moment';

export default {
  methods: {
    prettyFormatDate(date) {
      return moment(date).format("dddd DD MMMM YYYY");
    },
    prettyFormatDateHour(date) {
      return moment(date).format("dddd DD MMMM YYYY HH:mm");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateHour(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    formatHour(date) {
      return moment(date).isValid() ? moment(date).format("HH:mm") : " ";
    },
    formatHourFromTextInput(input) {
      input = String(input);
      let newValue = "";
      let h = input.match(/^\d{1,2}/g);
      if (h == null || h[0] < 0 || h[0] > 23) {
        newValue = "0:00";
      } else {
        if (h[0].length === 2 && h[0].startsWith("0")) {
            h = h[0].slice(-1);
        }
        let m = input.match(/^\d{1,2}[hH: ]?\d{2}$/g);
        if (m != null) {
          m = m[0].slice(-2);
          if (m < 0 || m > 59) {
              m = "00";
          }
        } else {
            m = "00"
        }
        newValue = h[0]+ ":" + m;
      }
      return newValue;
    },
    isToday(date) {
      return this.sameDay(date, new Date());
    },
    sameDay(date1, date2) {
      return moment(date1).isSame(date2, 'day');
    },
    sameWeek(date1, date2) {
      return moment(date1).isSame(date2, 'week');
    },
    findMinDateByDay(dateList) {
      let momentList = [];
      dateList.forEach(d => momentList.push(moment(d)))
      return moment.min(momentList).startOf('day').toDate();
    },
    findMaxDateByDay(dateList) {
      let momentList = [];
      dateList.forEach(d => momentList.push(moment(d)))
      return moment.max(momentList).endOf('day').toDate();
    },
    findStartOfWeek(date) {
      return moment(date).startOf('week').toDate();
    },
    findEndOfWeek(date) {
      return moment(date).endOf('week').toDate();
    },
    findMinDateByWeek(dateList) {
      let momentList = [];
      dateList.forEach(d => momentList.push(moment(d)))
      return moment.min(momentList).startOf('week').toDate();
    },
    findMaxDateByWeek(dateList) {
      let momentList = [];
      dateList.forEach(d => momentList.push(moment(d)))
      return moment.max(momentList).endOf('week').toDate();
    },
    findWeekNumber(date) {
      return moment(date).isoWeek();
    },
    findDayOfWeek(date) {
      return moment(date).isoWeekday();
    },
    addDays(date, days) {
      return moment(date, "DD-MM-YYYY").add(days, 'days');
    },
    findHours(date) {
      return moment(date, 'DD/MM/YYYY HH:mm').hours();
    },
    toDate(date) {
      return moment(date, 'DD/MM/YYYY HH:mm').toDate();
    },
    timestampToDate(timestamp) {
        return moment(timestamp).toDate();
    }
  }
}