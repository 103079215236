export default {
  methods: {
    sortCeiList(ceiList) {
      ceiList.sort((a,b) => {
        let c1 = a.agerLabel.localeCompare(b.agerLabel);
        if(c1 !== 0) return c1;
        let c2 = a.uerLabel.localeCompare(b.uerLabel);
        if(c2 !== 0) return c2;
        return a.label.localeCompare(b.label);
      });
    },
    
    initCeiListForTable(list, alreadySorted) {
      let nb = list.length;
      if (nb === 0) return;
      if (!alreadySorted) this.sortCeiList(list);
      
      let currentAgerId = list[nb - 1].agerId;
      let currentUerId = list[nb - 1].uerId;
      
      let currentAgerSize = 0;
      let currentUerSize = 0;
      
      for (let i = nb - 1; i >= 0; i--) {
        let cei = list[i];
        
        if (currentAgerId !== cei.agerId) {
          list[i + 1].agerSize = currentAgerSize;
          currentAgerSize = 0;
          currentAgerId = cei.agerId;
        }
        if (currentUerId !== cei.uerId) {
          list[i + 1].uerSize = currentUerSize;
          currentUerSize = 0;
          currentUerId = cei.uerId;
        }
        currentAgerSize++;
        currentUerSize++;
      }
      
      list[0].agerSize = currentAgerSize;
      list[0].uerSize = currentUerSize;
    }
  }
}